import { Controller } from "@hotwired/stimulus"
import ScrollTo from 'stimulus-scroll-to'

export default class extends ScrollTo {


  connect() {
    super.connect()
    // console.log(this.scroll)

    // terrible way of making it scroll to the default date
    const e = document.getElementsByName("default")[0]
    if (e) {
      const o = e.getBoundingClientRect().top + window.pageYOffset - 100
      window.scrollTo({top: o})
    }
  }

  // You can set default options in this getter for all your anchors.
  get defaultOptions() {
    return {
      offset: 100,
      behavior: 'auto',
    }
  }
}
