import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-preview"
export default class extends Controller {

  static targets = [ "input", "display", "image" ]

  connect() {
    this.fileList = new DataTransfer()
  }

  // helper function to display the image previews in the upload page
  showImages(files) {
    function convertImage(file) {
      display.innerHTML += `
          <div data-image-preview-target="image" class="thumbnail-image">
            <img src="${URL.createObjectURL(file)}"/>
            <div class="remove-btn" data-action="click->image-preview#removeUpload">+</div>
          </div>
            `;
    }

    var display = this.displayTarget
    display.innerHTML = ""
    Array.from(files).forEach(convertImage)
  }

  // function to preview photos when a photo gets selected
  previewAddedImages() {
    var input = this.inputTarget
    Array.from(input.files).forEach((file => {this.fileList.items.add(file)}))
    input.files = this.fileList.files

    if (input.files) {
      this.showImages(input.files)
    }
  }

  // function to update the previewed photos when a photo gets removed
  previewUpdatedImages() {
    if (this.fileList.files) {
      this.showImages(this.fileList.files)
    }
  }

  removeUpload() {

    // assigning some variables. dt is the new file array which will be assigned to the input.files without the element that we want to remove
    var input = this.inputTarget
    const {files} = input
    const dt = new DataTransfer()

    // collating all the images and finding the index of the one we want to remove
    const images = this.imageTargets
    const index = images.indexOf(event.currentTarget.parentElement)

    // iterating through the input.files and adding everything to dt except the one we want to remove
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (index !== i)
        dt.items.add(file)
    }

    // assign the updated lists
    input.files = dt.files
    this.fileList.items.clear()
    Array.from(input.files).forEach((file => {this.fileList.items.add(file)}))

    this.previewUpdatedImages()
  }

}
