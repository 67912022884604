import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapse"
export default class extends Controller {

  static targets = ["stay", "event", "transport"]

  connect() {
  }

  toggle() {
    console.log(event.target)
  }
}
