import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="directions"
export default class extends Controller {
  static targets = ["activity", "driving", "walking", "transit", "timings", "estimates", "caret"]

  connect() {
  }

  initMap() {
    this.activityTargets.forEach((activity, index) => {
      if (index != this.activityTargets.length - 1) {
        if (activity.dataset.coords != "none" && this.activityTargets[index + 1].dataset.coords != "none") {
          this.timingsTargets[index].innerHTML = "<h1>Click to see the estimated travel times to your next destination.</h1>"

          let origin_coords = activity.dataset.coords.split(",");
          let destination_coords = this.activityTargets[index + 1].dataset.coords.split(",");

          var service = new google.maps.DistanceMatrixService();

          const transport = ['DRIVING', 'TRANSIT', 'WALKING'];
          transport.forEach((mode) => {
            service.getDistanceMatrix(
              {
                origins: [{lat: parseFloat(origin_coords[0]), lng: parseFloat(origin_coords[1])}],
                destinations: [{lat: parseFloat(destination_coords[0]), lng: parseFloat(destination_coords[1])}],
                travelMode: mode,
              },
              (response, status) => {
                let duration = response.rows[0].elements[0].duration.text

                if (mode === 'DRIVING') {
                  this.drivingTargets[index].innerHTML =
                    `<a href="https://www.google.com/maps/dir/?api=1&origin=${origin_coords[0]}%2C${origin_coords[1]}&destination=${destination_coords[0]}%2C${destination_coords[1]}&&travelmode=driving" target="_blank">
                      <p><i class="fi fi-ts-car-side"></i></p>
                      <p>${duration}</p>
                    </a>`
                } else if (mode === 'TRANSIT') {
                  this.transitTargets[index].innerHTML =
                    `<a href="https://www.google.com/maps/dir/?api=1&origin=${origin_coords[0]}%2C${origin_coords[1]}&destination=${destination_coords[0]}%2C${destination_coords[1]}&&travelmode=transit" target="_blank">
                      <p><i class="fi fi-ts-bus-alt"></i></p>
                      <p>${duration}</p>
                    </a>`
                } else if (mode === 'WALKING') {
                  this.walkingTargets[index].innerHTML =
                  `<a href="https://www.google.com/maps/dir/?api=1&origin=${origin_coords[0]}%2C${origin_coords[1]}&destination=${destination_coords[0]}%2C${destination_coords[1]}&&travelmode=walking" target="_blank">
                    <p><i class="fi fi-ts-hiking"></i></p>
                    <p>${duration}</p>
                  </a>`
                }
              }
            )
          })
        } else {
          this.caretTargets[index].innerHTML = "";
          this.estimatesTargets[index].classList.remove("directions-card");
          this.estimatesTargets[index].classList.add("hidden");
        }
      } else {
        this.caretTargets[index].innerHTML = "";
        this.estimatesTargets[index].classList.remove("directions-card");
        this.estimatesTargets[index].classList.add("hidden");
      }
    })
  }
}
