import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigation"
export default class extends Controller {

  static targets = ["link"]

  connect() {
  }

  toggleActive() {
    this.linkTargets.forEach((link) => {
      link.classList.remove("active")})
    event.currentTarget.classList.add("active")
  }
}
