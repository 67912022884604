import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="flatpickr-event"
export default class extends Controller {
  static targets = ["startDateTime", "endDateTime", "dateRange", "defaultDate"]

  connect() {

    flatpickr(this.startDateTimeTarget, {
      disableMobile: true,
      minDate: this.dateRangeTarget.dataset.start,
      maxDate: this.dateRangeTarget.dataset.end,
      defaultDate: this.defaultDateTarget.dataset.default,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "j M Y, h.iK",
    })
    flatpickr(this.endDateTimeTarget, {
      disableMobile: true,
      minDate: this.dateRangeTarget.dataset.start,
      maxDate: this.dateRangeTarget.dataset.end,
      defaultDate: this.defaultDateTarget.dataset.default,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "j M Y, h.iK",
    })
  }
}
