// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "bootstrap"

function initMap () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })
  window.dispatchEvent(event)
}
window.initMap = initMap
document.addEventListener('turbo:load', initMap)
