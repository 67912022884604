import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = [ "name", "address", "coordinates", "arrival", "arrivaladdress", "departure", "departureaddress","arrivalcoords", "departurecoords" ]

  connect() {
    console.log("connected")
  }

  initMap() {
    if (this.hasNameTarget) { // These lines works for events and stays, which have only one address field
      this.autocomplete = new google.maps.places.Autocomplete(this.nameTarget)
      this.autocomplete.setFields(['formatted_address', 'geometry', 'name'])
      this.autocomplete.addListener('place_changed', () => {
        let place = this.autocomplete.getPlace()
        this.nameTarget.value = place.name
        this.addressTarget.value = place.formatted_address
        this.coordinatesTarget.value = place.geometry.location.lat() + ',' + place.geometry.location.lng()
      })
    } else { // These lines work for transports, which have departure and arrival fields
      this.departureAutocomplete = new google.maps.places.Autocomplete(this.departureTarget)
      this.departureAutocomplete.setFields(['formatted_address', 'geometry', 'name'])
      this.departureAutocomplete.addListener('place_changed', () => {
        let departure = this.departureAutocomplete.getPlace()
        this.departureTarget.value = departure.name
        this.departureaddressTarget.value = departure.formatted_address
        this.departurecoordsTarget.value = departure.geometry.location.lat() + ',' + departure.geometry.location.lng()
      })

      this.arrivalAutocomplete = new google.maps.places.Autocomplete(this.arrivalTarget)
      this.arrivalAutocomplete.setFields(['formatted_address', 'geometry', 'name'])
      this.arrivalAutocomplete.addListener('place_changed', () => {
        let arrival = this.arrivalAutocomplete.getPlace()
        this.arrivalTarget.value = arrival.name
        this.arrivaladdressTarget.value = arrival.formatted_address
        this.arrivalcoordsTarget.value = arrival.geometry.location.lat() + ',' + arrival.geometry.location.lng()
      })
    }
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
