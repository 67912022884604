import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ["startDate", "endDate"]

  connect() {
    flatpickr(this.startDateTarget, {
      disableMobile: true,
      minDate: new Date().fp_incr(-365),
      altInput: true,
      altFormat: "j M Y",
      plugins: [new rangePlugin({ input: this.endDateTarget })],
    })
    flatpickr(this.endDateTarget, {
      altInput: true,
      altFormat: "j M Y",
    })
  }
}
