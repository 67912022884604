import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modals"
export default class extends Controller {

  connect() {
    this.modal = document.getElementById('modal');
  }

  open() {
    this.modal.classList.remove('d-none');
  }


  close(e) {
    e.preventDefault();
    this.modal.classList.add('d-none');
    this.modal.innerHTML = "";

    this.modal.removeAttribute('src');
    this.modal.removeAttribute('complete')

  }
}
