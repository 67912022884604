import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-button"
export default class extends Controller {

  static targets = ["button", "text"]

  connect() {
  }

  toggle() {
    if (this.textTarget.innerText === "Edit photos") {
      this.textTarget.innerText = "Cancel edit"
      this.buttonTargets.forEach((button) => {
        button.classList.remove("d-none")})
    } else {
      this.textTarget.innerText = "Edit photos"
      this.buttonTargets.forEach((button) => {
        button.classList.add("d-none")}
      )
    }
  }
}
