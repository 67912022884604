import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-expand"
export default class extends Controller {
  static targets = [ "card", "caret" ]

  click() {
    var content = this.cardTarget;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      this.caretTarget.innerHTML = "▼";
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
      this.caretTarget.innerHTML = "▲";
    }
  }

  clickDirections() {
    var content = this.cardTarget;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      this.caretTarget.innerHTML = "➕";
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
      this.caretTarget.innerHTML = "➖";
    };
  }

  clickDocuments() {
    var content = this.cardTarget;
    if (content.style.maxHeight != 0 + "px") {
      content.style.maxHeight = 0 + "px";
      this.caretTarget.innerHTML = "▼";
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
      this.caretTarget.innerHTML = "▲";
    };
  }
}
